import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ children, title, description, image, location }) => {
  return (
    <Helmet titleTemplate={`%s - Ironhack`}>
      <html lang="en" />
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link
        rel="icon"
        type="image/svg+xml"
        href="https://cdn.ironhack.com/student-portal/favicon.ico"
      />
      <meta name="description" content="Ironhack alumni platform migration" />
      <title>{title}</title>

      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />
      <meta
        propery="og:site_name"
        content="Ironhack Student Platform Migration"
        key="ogsitename"
      />
      <meta property="og:image" content={image || '/ironhack-logo.svg'} />
      {location && <meta property="og:url" content={location.href} />}

      <meta name="twitter:title" content={title} />
      <meta
        name="twitter:description"
        content="Ironhack Student Platform Migration Portal"
      />
      <meta
        name="twitter:site_name"
        content="Ironhack Student Platform Migration Portal"
      />
      <meta name="twitter:image" content={image || '/ironhack-logo.svg'} />
      <meta name="twitter:creator" content="Ironhack" />

      {children}
    </Helmet>
  );
};

export default SEO;
